const requirementData = [
  'Attend 6 General Meetings (election meeting mandatory)',
  'Fulfill 10 volunteering hours (5 VIVOs max)',
  'Obtain 1 Committee Credit or 2 Fundraising Credit',
  'Physically attend 1 AGS sponsored Social or 2 SMC Workshop',
  'Cumulative 3.0 GPA and 6 SMC units',
  'Pay $10 in cash toward AGS Scholarship dues',
  'Do not receive three Negative 1\'s',
]

export default requirementData
