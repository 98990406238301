const navData = [
  {
    id: 1,
    title: 'Information',
    menuItems: [
      {
        id: 1,
        title: 'Social Events',
        url: 'https://instagram.com/agssmc',
        icon: 'group',
      },
      {
        id: 2,
        title: 'AGS Store',
        url: 'https://www.bonfire.com/store/agssmc/',
        icon: 'shopping_cart',
      },
      {
        id: 3,
        title: 'Newsletter',
        url: 'https://newsletter.agssmc.com/',
        icon: 'feed',
      },
      {
        id: 4,
        title: 'Alumni',
        url: 'https://alumni.agssmc.com',
        icon: 'school',
      },
    ],
  },
]

export default navData
