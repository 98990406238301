import Heading from 'components/Heading'
import Section from 'components/Section'
import Carousel from 'features/Carousel'
import Requirement from 'features/Requirement/Index'
import WhyAgsListing from 'features/WhyAgsListing'
import SectionIcon from 'components/SectionIcon'
import Button from 'components/Button'
import Testimonial from 'features/Testimonial'
import InstagramEmbed from 'features/InstagramEmbed'

const whyAgsListing = [
  {
    icon: 'volunteer_activism',
    heading: 'Community Services',
    paragraph: 'Volunteering is the heart of AGS, offering over 10,000 hours in any given semester. We strive to provide as many leadership opportunities as possible so that our members hone and craft their skills to stand far above the rest.',
  },
  {
    icon: 'diversity_3',
    heading: 'Developing Connections',
    paragraph: 'We are not all work, we play too. AGS offers an array of social events that allow members to meet some amazing new friends on their journey. We foster help foster bonds with other peers that will last a lifetime',
  },
  {
    icon: 'celebration',
    heading: 'Achievement Celebration',
    paragraph: 'AGS celebrates all of our members’ achievements and provides recognition for your hard work. Whether it’s through our President’s Volunteer Service or our own AGS chapter state awards and scholarship, we celebrate you, our hard-working member.',
  },
]

function Index() {
  return (
    <>
      {/* Homepage Carousel */}
      <Carousel />

      {/* Why AGS Section */}
      <Section>
        <Heading
          icon={(
            <SectionIcon
              className={'text-yellow-brand'}
            >
              {'bolt'}
            </SectionIcon>
          )}
          textClassName={'text-3xl'}
        >
          {'Why AGS?'}
        </Heading>

        <WhyAgsListing
          listing={whyAgsListing}
        />

        <Heading
          className={'justify-center mt-12'}
          textClassName={'text-center text-5xl'}
        >
          {'Our Instagram Page'}
        </Heading>

        <InstagramEmbed
          className={'mt-4'}
        />
      </Section>

      {/* Requirement Section */}
      <Section
        className={'bg-main'}
      >
        <Heading
          icon={(
            <SectionIcon
              className={'text-black-brand'}
            >
              {'lock'}
            </SectionIcon>
          )}
          textClassName={'text-3xl'}
        >
          {'Transcript Notation Requirements'}
        </Heading>

        <Requirement />
      </Section>

      {/* Newsletter Section */}
      <Section
        className={'bg-secondary-white-brand'}
      >
        <Heading
          icon={(
            <SectionIcon
              className={'text-yellow-brand'}
            >
              {'feed'}
            </SectionIcon>
                  )}
          textClassName={'text-3xl'}
        >
          {'Newsletter'}
        </Heading>

        <div
          className={'flex flex-col md:gap-4'}
        >
          <p
            className={'mt-4 font-body text-xl text-center md:!text-left leading-8'}
          >
            {'Keep up with monthly updates about the club through the club\'s newsletter website!'}
          </p>

          <Button
            className={'mt-4 block md:self-start text-xl bg-main text-black-brand border-black-brand hover:opacity-80 hover:bg-amber-300'}
            onClick={() => { window.location.href = 'https://newsletter.agssmc.com/' }}
          >
            {'Browse Newsletter'}
          </Button>
        </div>

      </Section>

      {/* Testimonial Section */}
      <Section
        className={'bg-white-brand'}
      >
        <Heading
          icon={(
            <SectionIcon
              className={'text-yellow-brand'}
            >
              {'Chat'}
            </SectionIcon>
                  )}
          textClassName={'text-3xl'}
        >
          {'Testimonials'}
        </Heading>

        <p
          className={'mt-4 font-body text-xl '}
        >
          {'Hear what our members have to say about us'}
        </p>

        <Testimonial
          className={'mt-4'}
        />
      </Section>
    </>
  )
}

export default Index
